
h1, h2, h3, h4, h5 {
    font-family: 'Concert One';
}

.ourpets {
  background-color: white;
}

.icon-gofundme {
  width: 120px;
}

.rehome {
    font-family: 'HelveticaNeueBold', 'HelveticaNeue-Bold', 'Helvetica Neue Bold', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, Roboto, 'Lucida Grande', sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #00a8de;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 48px;
}

.volunteer {
    color: teal;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 48px;
}

.adoption {
    color: darkslategray;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 48px;
}

.width100 {
  width: 100%;
}

.container-plus {
    margin-top: 70px;
  }

.bg-navbar {
  background-color: lightgray;
  color: black;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.links {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: teal;
  color: white;
  cursor: pointer;
}

.links:hover {
  background-color: orange;
}


.icon-large {
  font-size: 32px;
}

.menu-button-icon {
  width: 40px;
  margin-top: 5px;
}

.menu-button {
  padding-top: 10px;
  margin-left: 5px;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}

.menu-button-content {
  margin-top: 5px;
}

.menu-button .text {
  font-family: 'Playfair Display';
  font-weight: bold;
  font-size: 9px;
  color: black;
}

.menu-button:hover {
  background-color: black;
}

.menu-button:hover .text {
  color: white;
}


/* sm Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
}
 
/* md Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
}
 
/* lg Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  body {
    xbackground-image: url(../assets/bg.jpg);
  }

  .container-plus {
    margin-top: 70px;
  }

  .menu-button {
    padding-top: 1px;
    width: 60px;
    height: 60px;
  }

  .menu-button-content {
    margin-top: 8px;
  }

  .icon-large {
    font-size: 26px;
  }

  .menu-button-icon {
    width: 50px;
    margin-top: 10px;
  }

  
  .menu-button .text {
    font-size: 8px;
    padding-top: 2px;
  }

  .xbg-navbar {
    background-color: transparent;
    position: relative;
    z-index: 0;
  }
  
}
 
/* xl Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  body {
    xbackground-image: url(../assets/bg.jpg);
  }

  
  .ourpets {
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .container-plus {
    margin-top: 70px;
  }

  .menu-button {
    width: 85px;
    height: 85px;
  }

  .menu-button-content {
    margin-top: 20px;
  }

  .icon-large {
    font-size: 32px;
  }

  .menu-button-icon {
    width: 60px;
    margin-top: 20px;
  }
  
  .menu-button .text {
    font-size: 9px;
    padding-top: 5px;
  }

  .xbg-navbar {
    background-color: transparent;
    position: relative;
    z-index: 0;
  }
    
}

@media (min-width: 1600px) {
  .container-plus {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.container-njc {
  margin: auto;
  max-width: 980px;
  border: 10px solid white;
  background-color: rgba(255, 255, 255, 0.75);
  max-height: 600px;
  overflow: auto;
}

.container-plus {
  border: 10px solid white;
  background-color: rgba(255, 255, 255, 0.75);
}

.xcontainer-plus-inner {
  overflow: auto;
  max-height: 600px;
}

.pad-top {
  margin-top: 30px;
}

.menu ul{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.menu li {
  list-style: none;
  display: inline-block;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: darkslategray;
}

.clear {
  clear: both;
}

.header {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.toolbar-logo-small {
  width: 180px;
}

.bold {
  font-weight: bold;
}

.phone-email {
  font-size: 14px;
  font-weight: bold;
  margin-top: -15px;
  margin-left: 10px;
  color: black;
}


.footer-small {
  font-size: 10px;
  padding: 10px;
}

.menu-small {
  opacity: 1;
  background-color: black;
  color:grey;
  border-radius: 15%; 
  padding: 10px;
}

.bg-white {
  background-color: white;
}

.bg-red {
  background-color: darkred;
}

.bg-teal {
  background-color: teal;
}

.bg-cyan {
  background-color: cyan;
}

.bg-blue {
  background-color: slateblue;
}

.bg-yellow {
  background-color: lightgoldenrodyellow;
}

.bg-gold {
  background-color: goldenrod;
}

.bg-pink {
  background-color: lightcoral;
}

.about {
  padding: 40px;
  font-size: larger;
}

.about>div {
  display: inline;
}

.indent {
  padding-left: 20px;
}

.indent-right {
  padding-right: 20px;
}

.indent-top {
  padding-top: 15px;
}

.border-right {
  border-right: 1px solid black;
}

.border-left {
  border-left: 1px solid black;
}

.middle {
  vertical-align: middle;
}

.center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.weare501 {
  padding: 5px;
  background-color: black;
  width: 100%;
}

.weare501 > .text {
  padding: 5px;
  font-size: 11px;
  color: gold;
  border: 1px solid goldenrod;
}

.adopt {
  padding: 40px;
  font-size: larger;
}

.pet {
  padding: 10px;
}

.petname {
  font-family: 'Concert One';
  font-size: 20px;
  text-align: center;
  background-color: white;
  color: teal;
}

.links ul{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.links li {
  list-style: none;
  display: inline-block;
  background-color: teal;
  border-radius: 5px;
  padding: 20px;
  width: 22%;
  margin-bottom: 20px;
  font-family: "Concert One";
}

.links a {
    color: white;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
